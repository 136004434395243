import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";

ApiService.setHeader("x-api-key", store.getters.apiToken);

class DataStructuresBase {
  setUrl(url) {
    this.url = url;
    if (process.env.VUE_APP_VERSION >= 2) {
      this.url = process.env.VUE_APP_API_ADMIN;
    }
  }

  getUrl() {
    if (process.env.VUE_APP_VERSION >= 2) {
      return process.env.VUE_APP_API_ADMIN;
    }
    return this.url;
  }

  setRoute(route) {
    this.route = route;
  }

  setOptionalHeaders(headers) {
    this.optionalHeaders = headers;
  }

  get(id) {
    return ApiService.get(
      this.url,
      this.route + "/" + id,
      this.optionalHeaders
    );
  }

  getAll(params = { page: 1, perPage: 999 }, cancelToken) {
    let options = "?" + new URLSearchParams(params).toString();
    if ("order" in params) {
      options += "&orderBy[]=" + params.order;
    }
    this.setOptionalHeaders({ cancelToken });
    return ApiService.get(this.url, this.route + options, this.optionalHeaders);
  }

  getTemplate(id) {
    return ApiService.get(
      this.url,
      this.route + "/template/" + id,
      this.optionalHeaders
    );
  }

  getTemplates(params, admin = true) {
    let options = "?page=" + params.page + "&perPage=" + params.size;
    if (!admin) {
      options = "/templates" + options;
    }
    return ApiService.get(this.url, this.route + options, this.optionalHeaders);
  }

  getFieldTypes() {
    return ApiService.get(
      this.url,
      this.route + "/field_types",
      this.optionalHeaders
    );
  }

  store(data) {
    return ApiService.post(
      this.url + "/" + this.route,
      data,
      this.optionalHeaders
    );
  }

  update(id, data) {
    return ApiService.put(
      this.url + "/" + this.route + "/" + id,
      data,
      this.optionalHeaders
    );
  }

  delete(id) {
    return ApiService.delete(
      this.url + "/" + this.route + "/" + id,
      this.optionalHeaders
    );
  }

  analyze(data) {
    return ApiService.post(
      this.url + "/" + this.route + "/analyze",
      data,
      this.optionalHeaders
    );
  }

  truncate(id) {
    return ApiService.put(
      this.url + "/" + this.route + "/truncate/" + id,
      this.optionalHeaders
    );
  }

  setClean(id) {
    return ApiService.put(
      this.url + "/" + this.route + "/set_clean/" + id,
      this.optionalHeaders
    );
  }

  setDirty(id) {
    return ApiService.put(
      this.url + "/" + this.route + "/set_dirty/" + id,
      this.optionalHeaders
    );
  }

  unlock(id) {
    return ApiService.put(this.url + "/" + this.route + "/unlock/" + id, {});
  }

  updateQuery(queryData) {
    return ApiService.put(
      this.url + "/data_structure_queries/" + queryData.id,
      queryData
    );
  }

  storeQuery(queryData) {
    return ApiService.post(this.url + "/data_structure_queries", queryData);
  }

  deleteQuery(queryId) {
    return ApiService.delete(this.url + "/data_structure_queries/" + queryId);
  }
}

class DataStructures extends DataStructuresBase {
  constructor() {
    super();
    super.setRoute("data_structures");
    super.setUrl(process.env.VUE_APP_API_TENANT);
    if (this.appVersion >= 2) {
      super.setUrl(process.env.VUE_APP_API_ADMIN);
    }
  }

  getData(id, params = { page: 1, size: 10 }, cancelToken) {
    let options = "?" + new URLSearchParams(params).toString();
    let headers = {};
    if (cancelToken) {
      headers.cancelToken = cancelToken;
    }
    return ApiService.get(
      super.getUrl(),
      "data_structure_data/" + id + options,
      headers
    );
  }

  getDataStructureData(id, params = { page: 1, size: 10 }) {
    let options = "?" + new URLSearchParams(params).toString();
    return ApiService.get(
      super.getUrl(),
      "data_structure_data/" + id + options
    );
  }

  updateDataStructureData(dataStructureId, entryId, data) {
    return ApiService.put(
      super.getUrl() +
        "/data_structure_data/" +
        dataStructureId +
        "/" +
        entryId,
      data,
      this.optionalHeaders
    );
  }

  setEntryDirty(dataStructureId, entryId) {
    return ApiService.post(
      super.getUrl() +
        "/data_structure_data/" +
        dataStructureId +
        "/" +
        entryId +
        "/set_dirty",
      this.optionalHeaders
    );
  }

  setEntryClean(dataStructureId, entryId) {
    return ApiService.post(
      super.getUrl() +
        "/data_structure_data/" +
        dataStructureId +
        "/" +
        entryId +
        "/set_clean",
      this.optionalHeaders
    );
  }

  deleteDataStructureData(dataStructureId, entryId) {
    return ApiService.delete(
      super.getUrl() +
        "/data_structure_data/" +
        dataStructureId +
        "/" +
        entryId,
      this.optionalHeaders
    );
  }
}

class DataStructureTemplates extends DataStructuresBase {
  constructor(isAdmin = false) {
    super();
    super.setRoute("data_structure_templates");
    let url = isAdmin
      ? process.env.VUE_APP_API_ADMIN
      : process.env.VUE_APP_API_TENANT;

    if (this.appVersion >= 2) {
      url = process.env.VUE_APP_API_ADMIN;
    }

    super.setUrl(url);
  }
}

export { DataStructures, DataStructureTemplates };
