<template>
  <div>
    <div class="d-flex flex-row">
      <div
        id="kt_profile_aside"
        class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      >
        <div class="card card-custom">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                {{ textTemplate.label }}
              </h3>
            </div>
          </div>
          <div class="card-body pt-4">
            <div
              class="navi navi-bold navi-hover navi-active navi-link-rounded"
            >
              <div
                v-for="(item, i) in tabNavigation"
                :key="i"
                class="navi-item mb-2"
              >
                <router-link
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  :to="{ name: item.route, params: { type: item.type } }"
                  custom
                >
                  <a
                    :href="href"
                    class="navi-link py-4"
                    :class="{ active: $route.params.type === item.type }"
                  >
                    <span v-if="item.icon" class="navi-icon">
                      <v-icon>{{ item.icon }}</v-icon>
                    </span>
                    <span class="navi-text">{{ $t(item.label) }}</span>
                  </a>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="textTemplate.id" class="flex-row-fluid ml-lg-8">
        <div v-if="$route.name === 'projectTextTemplatesDetails'">
          <BasicConfiguration
            :is-busy="isBusy"
            :text-template="textTemplate"
            @update="updateTextTemplate"
            @back="back"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasicConfiguration from "@/components/Projects/TextTemplates/Details/BasicConfiguration";
import TextTemplate from "@/components/Projects/TextTemplates/text_templates";
const _ = require("lodash");

export default {
  components: { BasicConfiguration },
  props: {},
  data() {
    return {
      textTemplate: {},
      tabNavigation: [
        {
          route: "projectMappingsDetails",
          label: "mapping.detailsBasicConfiguration",
          icon: "fal fa-gear"
        }
      ],
      isBusy: false
    };
  },
  mounted() {
    this.loadTextTemplate();
  },
  methods: {
    loadTextTemplate() {
      this.isBusy = true;
      this.textTemplate = {};
      let textTemplateId = this.$route.params.id;
      TextTemplate.get(textTemplateId)
        .then(response => {
          this.textTemplate = response.data.data;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = false;
        });
    },
    updateTextTemplate(data) {
      this.isBusy = true;
      const props = ["id", "label", "type", "variables"];
      let requestData = _.pick(data, props);

      requestData["variables"] = requestData["variables"].map(function(
        variable
      ) {
        variable["dataStructure"] =
          variable["dataStructure"] && variable["dataStructure"]["id"]
            ? variable["dataStructure"]["id"]
            : variable["dataStructure"];
        return variable;
      });

      TextTemplate.update(requestData.id, requestData)
        .then(response => {
          this.textTemplate = response.data.data;
          this.$toast.fire({
            icon: "success",
            title: this.$t("textTemplates.textTemplateUpdated")
          });
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = false;
        });
    },
    back() {
      this.$router.push({ name: "projectTextTemplates" });
    }
  }
};
</script>
