<template>
  <div>
    <Editor v-if="$route.name === 'projectTextTemplatesEditor'" />
    <Create v-else-if="$route.name === 'projectTextTemplatesCreate'" />
    <Details v-else-if="$route.name === 'projectTextTemplatesDetails'" />
    <Table v-else />
  </div>
</template>

<script>
import Table from "@/components/Projects/TextTemplates/Table";
import Create from "@/components/Projects/TextTemplates/Create";
import Details from "@/components/Projects/TextTemplates/Details";
import Editor from "@/components/Projects/TextTemplates/Editor";

export default {
  components: { Table, Create, Details, Editor },
  props: [],
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
