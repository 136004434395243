<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">
        {{ $t("textTemplates.detailsBasicConfiguration") }}
      </div>
      <div class="card-toolbar">
        <button class="btn btn-success" @click="updateTextTemplate">
          {{ $t("general.save") }}
        </button>
        <button class="btn btn-secondary ml-1" @click="back">
          {{ $t("general.cancel") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body">
      <div class="row">
        <label class="col-xl-3"></label>
        <div class="col-lg-9 col-xl-6">
          <h5 class="font-weight-bold">
            {{ $t("textTemplates.textTemplateCreate") }}:
          </h5>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("textTemplates.textTemplateLabel") }}
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <input
            v-model="$v.textTemplateData.label.$model"
            type="text"
            class="form-control"
            :class="{
              'is-invalid': $v.textTemplateData.label.$error,
              'is-valid':
                $v.textTemplateData.label.$dirty &&
                !$v.textTemplateData.label.$error
            }"
          />
        </div>
      </div>

      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("textTemplates.type") }}
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <v-select
            v-model="textTemplateData.type"
            :items="fileTypes"
            :item-text="type => `${type.label}`"
            item-value="key"
            :menu-props="{ offsetY: true }"
            class="form-control"
          />
        </div>
      </div>

      <div class="row form-group">
        <label class="col-xl-3 col-lg-3 col-form-label">
          <div class="mt-3">{{ $t("textTemplates.variables") }}</div>
        </label>
        <div class="col-md-9 col-lg-8 col-xl-9">
          <div
            v-for="(item, i) in textTemplateData.variables"
            :key="i"
            class="d-flex align-items-center mb-1 row"
          >
            <div class="input-group col-lg-4">
              <input
                v-model="textTemplateData.variables[i].name"
                type="text"
                class="form-control"
                placeholder="Name"
              />
            </div>
            <div class="input-group col-lg-3">
              <v-select
                v-model="textTemplateData.variables[i].type"
                :items="types"
                :item-text="type => `${type.label}`"
                item-value="key"
                :menu-props="{ offsetY: true }"
                class="form-control"
              />
            </div>
            <div
              v-if="
                textTemplateData.variables[i].type === 'dataStructure' ||
                  textTemplateData.variables[i].type === 'dataStructureList'
              "
              class="input-group col-lg-4"
            >
              <v-select
                v-model="textTemplateData.variables[i].dataStructure"
                :items="dataStructures"
                :item-text="ds => `${ds.classname} - v.${ds.version}`"
                item-value="id"
                :menu-props="{ offsetY: true }"
                class="form-control"
              />
            </div>
            <i
              class="la la-minus-circle icon-lg text-danger cursor-pointer col-lg-1"
              @click="removeField(i)"
            />
            <div class="separator separator-solid col-12"></div>
          </div>
          <div class="d-flex justify-space-between align-items-center">
            <button class="btn btn-primary" @click="addField">
              <i class="la la-plus" />{{ $t("textTemplates.addVariable") }}
            </button>
            <i
              v-b-popover.hover.top="$t('textTemplates.variablesHelp')"
              class="far fa-question-circle ml-1"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { minLength, required } from "vuelidate/lib/validators";
import { DataStructures } from "@/components/Admins/Settings/DataStructures/dataStructures";
const _ = require("lodash");
const DataStructureService = new DataStructures();

export default {
  components: {},
  props: {
    textTemplate: {
      type: Object,
      default: () => {}
    },
    isBusy: {
      type: Boolean
    }
  },
  data() {
    return {
      textTemplateData: _.cloneDeep(this.textTemplate),
      dataStructures: [],
      types: [
        {
          key: "string",
          label: this.$t("textTemplates.string")
        },
        {
          key: "dataStructure",
          label: this.$t("textTemplates.dataStructure")
        },
        {
          key: "dataStructureList",
          label: this.$t("textTemplates.dataStructureList")
        }
      ],
      fileTypes: [
        {
          key: "csv",
          label: "CSV"
        },
        {
          key: "xml",
          label: "XML"
        },
        {
          key: "html",
          label: "HTML"
        },
        {
          key: "edifact",
          label: "EDIFACT"
        },
        {
          key: "datanorm",
          label: "Datanorm"
        },
        {
          key: "json",
          label: "JSON"
        },
        {
          key: "other",
          label: this.$t("textTemplates.otherFileType")
        },
      ]
    };
  },
  validations() {
    return {
      textTemplateData: {
        label: { required, minLength: minLength(3) }
      }
    };
  },
  mounted() {
    this.loadDataStructures();
  },
  methods: {
    loadDataStructures() {
      let params = {
        page: 1,
        size: 999
      };
      DataStructureService.getAll(params)
        .then(response => {
          this.dataStructures = response.data.data;
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
        });
    },
    updateTextTemplate() {
      this.$emit("update", this.textTemplateData);
    },
    back() {
      this.$emit("back");
    },
    addField() {
      this.textTemplateData.variables.push({
        type: "",
        dataStructure: "",
        name: ""
      });
    },
    removeField(key) {
      this.textTemplateData.variables.splice(key, 1);
    }
  }
};
</script>
