<template>
  <div>
    <div class="card card-custom mb-3 ds-header">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("textTemplates.edit") }}
          </h3>
        </div>
        <div class="card-toolbar">
          <button
            class="btn btn-secondary mr-1"
            @click.left="back"
            @click.middle="backNewTab"
          >
            {{ $t("workflowDesigner.backToOverview") }}
          </button>
          <button
            v-b-tooltip.bottom="$t('general.save')"
            class="btn btn-icon btn-primary mr-1"
            @click="save"
          >
            <i class="fal fa-floppy-disk" />
          </button>
          <button
            v-b-tooltip.bottom="$t('workflowElements.testWorkflow')"
            class="btn btn-primary btn-icon mr-1"
            @click="test"
          >
            <i class="fal fa-play" />
          </button>
          <!--          <button class="btn btn-primary" @click="saveAndTest">-->
          <!--            {{ $t("workflowDesigner.saveAndTest") }}-->
          <!--          </button>-->
        </div>
      </div>
      <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    </div>

    <div class="row mb-2 ">
      <div class="col-3">
        <div
          class="card card-custom grid-stack-item-content"
          style="height: calc(100vh - 158px);"
        >
          <div class="card-body">
            <div class="row">
              <div
                v-for="(variable, index) in textTemplate.variables"
                :key="index"
                class="col text-center border-bottom"
                :class="selectedVariable === index ? '' : ''"
              >
                <b-link
                  :class="
                    selectedVariable === index ? 'text-primary' : 'text-muted'
                  "
                  @click="selectedVariable = index"
                  >{{ variable["name"] }}
                </b-link>
              </div>
            </div>

            <!--            <div-->
            <!--              v-if="-->
            <!--                textTemplate &&-->
            <!--                  textTemplate.variables &&-->
            <!--                  textTemplate.variables[selectedVariable]['dataStructure']-->
            <!--              "-->
            <!--              style="flex-grow: 1; min-height: 0;"-->
            <!--              class="py-8"-->
            <!--            >-->
            <perfect-scrollbar
              v-if="textTemplate && textTemplate.variables && textTemplate.variables.length > 0"
              id="scroll-start"
              class="scroll h-100 p-4 row"
            >
              <div id="tree-start">
                <div
                  v-if="
                    textTemplate.variables[selectedVariable] &&
                      textTemplate.variables[selectedVariable].type === 'string'
                  "
                  class="fieldset tree-node-start tree-node px-4 py-2"
                >
                  <div
                    class="tree-node-content d-flex justify-content-between align-items-center cursor-pointer"
                    @click="
                      stringToClipboard(
                        textTemplate.variables[selectedVariable].slug
                      )
                    "
                  >
                    <div class="mr-1">
                      <i class="type-icon mr-1 fal fa-edit" />
                      <span class="mr-1">{{
                        textTemplate.variables[selectedVariable].name
                      }}</span>
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    textTemplate.variables[selectedVariable] &&
                      textTemplate.variables[selectedVariable][
                        'dataStructure'
                      ] &&
                      textTemplate.variables[selectedVariable].type ===
                        'dataStructureList'
                  "
                  class="fieldset tree-node-start tree-node px-2"
                >
                  <div
                    class="tree-node-content d-flex justify-content-between align-items-center cursor-pointer"
                    @click="
                      listToClipboard(
                        textTemplate.variables[selectedVariable].slug
                      )
                    "
                  >
                    <div class="mr-1">
                      <i class="type-icon mr-1 fal fa-folder-open" />
                      <span class="mr-1">{{
                        textTemplate.variables[selectedVariable].name
                      }}</span>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    textTemplate.variables[selectedVariable]['dataStructure']
                  "
                  :class="{
                    'ml-6':
                      textTemplate.variables[selectedVariable] ? textTemplate.variables[selectedVariable].type ===
                        'dataStructureList'
                      : ''
                  }"
                >
                  <Node
                    v-for="(field, i) in filteredFields"
                    :key="i"
                    :field="field"
                    :slug="
                      textTemplate.variables[selectedVariable].slug +
                        (textTemplate.variables[selectedVariable].type ===
                        'dataStructureList'
                          ? '_entry'
                          : '')
                    "
                    :selected-data-structure-data="
                      selectedDataStructureData[selectedVariable]
                    "
                    :source-fields="
                      textTemplate.variables[selectedVariable]['dataStructure'][
                        'fields'
                      ]
                    "
                  />
                </div>
              </div>
            </perfect-scrollbar>
            <div v-else class="py-8 px-4">
              Keine Variablen konfiguriert.
            </div>
            <!--            </div>-->
          </div>
        </div>
      </div>
      <div class="col-6">
        <div
          class="card card-custom grid-stack-item-content"
          style="min-height: calc(100vh - 158px);"
        >
          <div class="card-body">
            <prism-editor
              v-model="textTemplate.content"
              class="editor code-editor"
              :highlight="prismEditorHighlighter"
              :tab-size="tabSize"
              :style="editorFsStyle"
              line-numbers
            />
          </div>
        </div>
      </div>
      <div class="col-3">
        <Helper :text-template-type="textTemplate.type"></Helper>
      </div>
    </div>

    <b-modal
      ref="testWorkflowModal"
      v-model="testTextTemplateShowModal"
      hide-footer
      size="xl"
      :title="
        $t('processManager.testProcess', {
          name: textTemplate.label
        })
      "
    >
      <div class="row">
        <div class="col col-lg-4 pr-12">
          <div
            v-for="(variable, index) in textTemplate.variables"
            :key="index"
            class="row mb-2 align-items-center"
          >
            <label class="col-xl-3 col-lg-3 col-form-label">
              {{ variable.name }}
            </label>

            <div class="col">
              <input
                v-if="variable.type === 'string'"
                v-model="selectedDebugData[variable.slug]"
                type="text"
                class="form-control"
              />
              <v-select
                v-else
                v-model="selectedDebugData[variable.slug]"
                :items="selectedDataStructureData[index]"
                :item-text="item => `${item._primary}`"
                item-value="_primary"
                return-object
                :multiple="variable.type === 'dataStructureList'"
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="col col-lg-8">
          <div class="debug-result">
            <div>{{ debugTemplate }}</div>
          </div>
          <div
            v-if="debugValid !== null && textTemplate.type"
            class="validation mt-4"
          >
            <span v-if="debugValid" class="is-valid" style="color: #20ab20"
              >{{ $t("textTemplates.valid") }} ({{ textTemplate.type }})</span
            >
            <span v-if="!debugValid" class="is-invalid" style="color: #ff3553">
              {{ $t("textTemplates.invalid") }} ({{ textTemplate.type }})
            </span>
          </div>
          <div v-if="!debugValid && debugErrors" class="debug-errors">
            <div v-for="(error, key) in debugErrors" :key="key">
              <hr />
              <div>{{ error }}</div>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col col-lg-4 pr-12">
          <div class="mt-7 d-flex justify-content-end">
            <button
              class="btn btn-secondary mr-1"
              @click="testTextTemplateClose"
            >
              {{ $t("general.cancel") }}
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              @click="testTextTemplateSubmit"
            >
              {{ $t("processManager.test") }}
            </button>
          </div>
        </div>
        <div class="col col-lg-8">
          <div class="mt-7 d-flex justify-content-end">
            <input
              v-model="demoFileName"
              type="text"
              class="form-control w-25 mr-2"
            />
            <button type="submit" class="btn btn-primary" @click="download">
              {{ $t("textTemplates.download") }}
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<style lang="scss">
.debug-result {
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  white-space: pre;
  background: #f7f7f7;
  padding: 8px;
  min-height: 192px;
  overflow: auto;
}
.debug-errors {
  white-space: pre;
}
.editor {
  background: #2d2d2d;
  color: #ccc;
  min-height: calc(100vh - 211px);
  padding: 10px 0;
  pre {
    color: #ccc;
  }
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 13px;
  line-height: 1.5;
  border: 1px solid #e4e6ef;
}
.prism-editor__container {
  height: 100%;
  //min-height: 300vh !important;
}
.prism-editor__textarea:focus {
  outline: none;
}
.multipane-resizer {
  background: white;
}
#tree-start {
  .tree-node {
    border-radius: 24px;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.3s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }

    .type-icon {
      font-size: 1rem;
    }

    .tree-node-content {
      height: 20px;
    }

    .btn-toggle-children {
      transition: all 0.3s;
      background-color: rgba(0, 0, 0, 0);

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }

      i {
        transform: rotate(-90deg);
        transition: all 0.3s;
      }

      &.open {
        i {
          transform: rotate(0deg);
        }
      }
    }
  }
}
</style>

<script>
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-markup.js";
import "prismjs/components/prism-markup-templating.js";
import "prismjs/components/prism-twig";
import TextTemplate from "@/components/Projects/TextTemplates/text_templates";
import { DataStructures } from "@/components/Admins/Settings/DataStructures/dataStructures";

const DataStructureService = new DataStructures();

export default {
  components: {
    PrismEditor,
    Node: () => import("@/components/Projects/TextTemplates/Editor/Node"),
    Helper: () => import("@/components/Projects/TextTemplates/Editor/Helper")
  },
  props: {
    textTemplateId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      isBusy: 0,
      demoFileName: "test.txt",
      selectedVariable: 0,
      clipboardData: "test",
      selectedDebugData: {},
      textTemplate: {},
      sourceItems: ["ID: 123", "ID: 231", "ID: 412"],
      text: "Test",
      tabSize: 4,
      leftMenu: {},
      testTextTemplateShowModal: false,
      editorFs: false,
      selectedDataStructureData: {},
      debugTemplate: "",
      debugValid: null,
      debugErrors: [],
      dataStructureService: DataStructureService,
      fileTypes: [
        {
          name: "twig",
          label: "Twig",
          style: "",
          showAlways: true
        },
        {
          name: "xml",
          label: "XML",
          style: ""
        },
        {
          name: "edifact",
          label: "EDIFACT",
          style: ""
        },
        {
          name: "html",
          label: "HTML",
          style: ""
        }
      ]
    };
  },
  computed: {
    editorFsStyle() {
      if (!this.editorFs) {
        return "";
      }
      return "min-height: 85vh !important;";
    },
    activeTypes() {
      return this.fileTypes.filter(type => {
        return type.name === this.textTemplate.type || type.showAlways;
      });
    },
    filteredFields() {
      if (
        !this.textTemplate ||
        !this.textTemplate.variables ||
        !this.textTemplate.variables[this.selectedVariable]
      ) {
        return [];
      }

      let fields = this.textTemplate.variables[this.selectedVariable] ? this.textTemplate.variables[this.selectedVariable][
        "dataStructure"
      ]["fields"] : [];
      return fields.filter(field => {
        return field.parent_id === null;
      });
    },
    paneSizes() {
      return {
        leftMenu: {
          test: false,
          visible: true,
          minWidth: "52px",
          maxWidth: "52px",
          width: "52px",
          flexGrow: 0
        }
      };
    }
  },
  mounted() {
    this.loadTextTemplate();
  },
  methods: {
    testTextTemplateClose() {
      this.testTextTemplateShowModal = false;
    },
    testTextTemplateSubmit() {
      this.isBusy = true;
      TextTemplate.execute(
        this.textTemplate.id,
        this.selectedDebugData,
        this.textTemplate.content
      )
        .then(response => {
          this.debugTemplate = response.data.data;

          if (response.data.valid !== null) {
            this.debugValid = response.data.valid;
            this.debugErrors = response.data.errors;
          }

          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = false;
        });
    },
    download() {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent(this.debugTemplate)
      );
      element.setAttribute("download", this.demoFileName);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    loadTextTemplate() {
      this.isBusy = true;
      this.textTemplate = {};
      let textTemplateId = this.$route.params.id;
      TextTemplate.get(textTemplateId)
        .then(response => {
          this.textTemplate = response.data.data;
          this.isBusy = false;

          if (this.textTemplate.type) {
            this.demoFileName = "test." + this.textTemplate.type;
          }

          this.loadDataStructureData();
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = false;
        });
    },
    loadDataStructureData() {
      let dataStructureService = this.dataStructureService;

      this.textTemplate.variables.forEach((item, key) => {
        if (!item["dataStructure"]) {
          return;
        }

        dataStructureService
          .getDataStructureData(item["dataStructure"]["id"])
          .then(response => {
            this.$set(this.selectedDataStructureData, key, response.data.data);
          });
      });
    },
    saveAndTest() {
      this.save();
    },
    test() {
      this.testTextTemplateShowModal = true;
    },
    save() {
      this.isBusy = true;
      TextTemplate.update(this.textTemplate.id, {
        label: this.textTemplate.label,
        type: this.textTemplate.type,
        content: this.textTemplate.content,
        variables: this.textTemplate.variables
          ? this.textTemplate.variables.map(function(variable) {
              return {
                type: variable.type,
                name: variable.name,
                dataStructure: variable.dataStructure
                  ? variable.dataStructure.id
                  : null
              };
            })
          : []
      })
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("textTemplates.templateSaved")
          });
          this.isBusy = false;
          this.saveFinished = true;
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
          this.isBusy = false;
        });
    },
    prismEditorHighlighter(code) {
      return highlight(code, languages.twig);
    },
    back(event) {
      if (event && (event.ctrlKey || event.metaKey)) {
        this.backNewTab();
        return;
      }

      this.$router.push({ name: "projectTextTemplates" });
    },
    backNewTab() {
      const route = this.$router.resolve({
        name: "projectTextTemplates"
      });
      window.open(route.href, "_blank");
    },
    listToClipboard(slug) {
      let copyText =
        "{% for " +
        slug +
        "_key, " +
        slug +
        "_entry in " +
        slug +
        " %}\n    \n{% endfor %}";

      this.$copyText(copyText);
    },
    stringToClipboard(slug) {
      this.$copyText("{{ " + slug + " }}");
    }
  }
};
</script>
