<template>
  <div class="card card-custom">
    <div class="card-header">
      <div class="card-title">
        {{ $t("textTemplates.textTemplateCreate") }}
      </div>
      <div class="card-toolbar">
        <button
          class="btn btn-secondary"
          @click="$router.push({ name: 'projectTextTemplates' })"
        >
          {{ $t("general.cancel") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body">
      <div class="row">
        <label class="col-xl-3"></label>
        <div class="col-lg-9 col-xl-6">
          <h5 class="font-weight-bold">
            {{ $t("textTemplates.textTemplateCreate") }}:
          </h5>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("textTemplates.textTemplateLabel") }}
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <input
            v-model="$v.textTemplate.label.$model"
            type="text"
            class="form-control"
            :class="{
              'is-invalid': $v.textTemplate.label.$error,
              'is-valid':
                $v.textTemplate.label.$dirty && !$v.textTemplate.label.$error
            }"
          />
        </div>
      </div>

      <div class="form-group row align-items-center">
        <label class="col-xl-3 col-lg-3 col-form-label">
          {{ $t("textTemplates.type") }}
        </label>
        <div class="col-md-9 col-lg-6 col-xl-4">
          <v-select
            v-model="textTemplate.type"
            :items="fileTypes"
            :item-text="type => `${type.label}`"
            item-value="key"
            :menu-props="{ offsetY: true }"
            class="form-control"
          />
        </div>
      </div>

      <div class="row form-group">
        <label class="col-xl-3 col-lg-3 col-form-label">
          <div class="mt-3">{{ $t("textTemplates.variables") }}</div>
        </label>
        <div class="col-md-9 col-lg-8 col-xl-9">
          <div
            v-for="(item, i) in textTemplate.variables"
            :key="i"
            class="d-flex align-items-center mb-1 row"
          >
            <div class="col-11 row">
              <div class="input-group col-lg-4 flex-column">
                <label>
                  {{ $t("textTemplates.name") }}
                </label>
                <div>
                  <input
                    v-model="textTemplate.variables[i].name"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="input-group col-lg-4 flex-column">
                <label>
                  {{ $t("textTemplates.type") }}
                </label>
                <div>
                  <v-select
                    v-model="textTemplate.variables[i].type"
                    :items="types"
                    :item-text="type => `${type.label}`"
                    item-value="key"
                    :menu-props="{ offsetY: true }"
                    class="form-control mt-0"
                  />
                </div>
              </div>

              <div
                v-if="
                  textTemplate.variables[i].type === 'dataStructure' ||
                    textTemplate.variables[i].type === 'dataStructureList'
                "
                class="input-group col-lg-4 flex-column"
              >
                <label>
                  {{ $t("textTemplates.datastructure") }}
                </label>
                <div>
                  <v-select
                    v-model="textTemplate.variables[i].dataStructure"
                    :items="dataStructures"
                    :item-text="ds => `${ds.label} - v.${ds.version}`"
                    item-value="id"
                    :menu-props="{ offsetY: true }"
                    class="form-control mt-0"
                    name="datastructure"
                  />
                </div>
              </div>
            </div>

            <div class="col-1">
              <i
                class="far fa-minus-circle icon-lg text-danger cursor-pointer col-lg-1 mt-6"
                @click="removeField(i)"
              />
            </div>

            <div class="separator separator-solid col-12"></div>
          </div>
          <div class="d-flex justify-space-between align-items-center">
            <button class="btn btn-primary" @click="addField">
              <i class="la la-plus" />{{ $t("textTemplates.addVariable") }}
            </button>
            <i
              v-b-popover.hover.top="$t('textTemplates.variablesHelp')"
              class="far fa-question-circle ml-1"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer d-flex align-items-center justify-content-end">
      <button
        class="btn btn-success"
        :disabled="isBusy"
        @click="saveTextTemplate"
      >
        {{ $t("textTemplates.textTemplateSave") }}
      </button>
    </div>
  </div>
</template>

<script>
import { DataStructures } from "@/components/Admins/Settings/DataStructures/dataStructures";
const DataStructureService = new DataStructures();

import { required, minLength } from "vuelidate/lib/validators";
import TextTemplate from "@/components/Projects/TextTemplates/text_templates";

export default {
  components: {},
  props: {},
  data() {
    return {
      textTemplate: {
        label: "",
        type: "other",
        variables: []
      },
      types: [
        {
          key: "string",
          label: this.$t("textTemplates.string")
        },
        {
          key: "dataStructure",
          label: this.$t("textTemplates.dataStructure")
        },
        {
          key: "dataStructureList",
          label: this.$t("textTemplates.dataStructureList")
        }
      ],
      fileTypes: [
        {
          key: "csv",
          label: "CSV"
        },
        {
          key: "xml",
          label: "XML"
        },
        {
          key: "html",
          label: "HTML"
        },
        {
          key: "edifact",
          label: "EDIFACT"
        },
        {
          key: "datanorm",
          label: "Datanorm"
        },
        {
          key: "json",
          label: "JSON"
        },
        {
          key: "other",
          label: this.$t("textTemplates.otherFileType")
        }
      ],
      dataStructures: [],
      isBusy: false
    };
  },
  validations() {
    return {
      textTemplate: {
        label: { required, minLength: minLength(3) }
      }
    };
  },
  mounted() {
    this.loadDataStructures();
  },
  methods: {
    loadDataStructures() {
      this.isBusy = true;
      let params = {
        noPagination: true
      };
      DataStructureService.getAll(params)
        .then(response => {
          this.dataStructures = response.data.data;
          this.isBusy = false;
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
          this.isBusy = false;
        });
    },
    saveTextTemplate() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.isBusy = true;
      let data = this.textTemplate;
      TextTemplate.store(data)
        .then(response => {
          this.isBusy = false;
          this.$toast.fire({
            icon: "success",
            title: this.$t("textTemplates.textTemplateSaved")
          });
          this.$router.push({
            name: "projectTextTemplatesEditor",
            params: { id: response.data.data.id }
          });
        })
        .catch(error => {
          this.$swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
          this.isBusy = false;
        });
    },
    addField() {
      this.textTemplate.variables.push({
        type: "",
        dataStructure: ""
      });
    },
    removeField(key) {
      this.textTemplate.variables.splice(key, 1);
    }
  }
};
</script>
