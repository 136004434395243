<template>
  <div id="integrationsCard" class="card card-custom grid-stack-item-content">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ $t("textTemplates.textTemplates") }}</h3>
      </div>
      <div class="card-toolbar">
        <button class="btn btn-primary btn-hover" @click="createTextTemplate">
          <i class="fal fa-plus"></i>
          {{ $t("textTemplates.textTemplateCreate") }}
        </button>
      </div>
    </div>

    <v-progress-linear v-if="isBusy" indeterminate color="primary" />

    <div class="card-body column">
      <TableWrapper
        :meta="meta"
        @reload-data="loadTextTemplates"
      >
        <b-table
          responsive
          :items="textTemplates"
          :fields="fields"
          class="dataTable table table-head-custom"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(actions)="data">
            <button
              class="btn btn-icon btn-light btn-sm"
              @click.left="openTextTemplateEditor(data.item)"
              @click.middle="openTextTemplateEditor(data.item)"
            >
              <i class="fal fa-paint-brush" />
            </button>
            <button
              class="btn btn-icon btn-light btn-sm ml-1"
              @click.left="openDetails(data.item)"
              @click.middle="openDetails(data.item)"
            >
              <i class="fal fa-pen" />
            </button>
            <button class="btn btn-icon btn-light btn-sm ml-1" @click="deleteTextTemplate(data.item)">
              <i class="fal fa-trash" />
            </button>
          </template>
        </b-table>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TextTemplates from "@/components/Projects/TextTemplates/text_templates";
import TableWrapper from "@/components/Tools/TableWrapper";
import {mapGetters} from "vuex";

export default {
  components: { TableWrapper },
  props: [],
  data() {
    return {
      textTemplates: [],
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true
        },
        {
          key: "label",
          label: this.$t("table.name"),
          sortable: true
        },
        {
          key: "type",
          label: this.$t("textTemplates.type"),
          sortable: true
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          class: "text-right"
        }
      ],
      // Meta info
      meta: {},
      isBusy: false
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams"])
  },
  mounted() {
    this.setBreadcrumbs();
    this.loadTextTemplates();
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("menu.projectTextTemplates"),
          route: ""
        },
        {
          title: this.$t("menu.projectsOverview"),
          route: "/project/text-templates"
        }
      ]);
    },
    loadTextTemplates() {
      this.isBusy = true;
      let params = this.requestParams();
      TextTemplates.getAll(params)
        .then(response => {
          this.textTemplates = response.data.data;
          this.meta = response.data.meta;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = false;
        });
    },
    createTextTemplate() {
      this.$router.push({ name: "projectTextTemplatesCreate" });
    },
    openTextTemplateEditor(textTemplate) {
      this.$router.push({
        name: "projectTextTemplatesEditor",
        params: { id: textTemplate.id }
      });
    },
    openDetails(textTemplate) {
      this.$router.push({
        name: "projectTextTemplatesDetails",
        params: { id: textTemplate.id }
      });
    },
    deleteTextTemplate(textTemplate) {
      this.$swal
        .fire({
          icon: "warning",
          title: this.$t("textTemplates.deleteTextTemplateTitle", {
            title: textTemplate.label
          }),
          text: this.$t("textTemplates.deleteTextTemplateText"),
          showConfirmButton: true,
          confirmButtonText: this.$t("general.delete"),
          confirmButtonColor: "var(--primary)",
          showCancelButton: true,
          cancelButtonText: this.$t("general.cancel"),
          cancelButtonColor: "var(--secondary)"
        })
        .then(result => {
          if (!result.isConfirmed) {
            return;
          }
          this.isBusy = true;
          TextTemplates.delete(textTemplate.id)
            .then(() => {
              this.$toast.fire({
                icon: "success",
                title: this.$t("textTemplates.textTemplateDeleted")
              });
              this.loadTextTemplates();
            })
            .catch(error => {
              this.$swal.fire({
                icon: "error",
                title: this.$t("general.caution"),
                text: error.response?.data?.message
              });
              this.isBusy = false;
            });
        });
    }
  }
};
</script>
